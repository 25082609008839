import React, { useEffect, useState } from "react";
import { useGetCashBalanceTotal } from "../../../api/Finance.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Card, CardBody, CardSubtitle } from "reactstrap";

const formatter = new Intl.NumberFormat();

const CashBalance = () => {
  const {
    data: cashBalanceTotal,
    error: cashBalanceErr,
    isLoading: isLoadingCashBalance,
    get: getCashBalanceTotal,
  } = useGetCashBalanceTotal();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ total: 0 });

  useEffect(() => {
    getCashBalanceTotal();
  }, [getCashBalanceTotal]);

  useEffect(() => {
    if (cashBalanceTotal) {
      setData(cashBalanceTotal);
    }
  }, [cashBalanceTotal]);

  useEffect(() => {
    if (cashBalanceErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [cashBalanceErr]);

  return (
    <div className="col-6">
      {isLoadingCashBalance ? (
        <Loader />
      ) : (
        <div>
          <Card className="card-rounded">
            <CardBody className="pl-4">
              <h1 className="font-weight-bold display-5">{`$${formatter.format(
                data.total
              )}`}</h1>
              <CardSubtitle className="">Cash Balance Total</CardSubtitle>
            </CardBody>
          </Card>
        </div>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default CashBalance;
