import React, { useEffect, useState } from "react";

import { useGetPastWeekOrders } from "../../../api/Orders.hooks";
import { Container } from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Bar } from "react-chartjs-2";
import { utils } from "../../../utils/utils";

import { Chart as ChartJS, Tooltip, Legend, Filler, Title } from "chart.js";

ChartJS.register(Tooltip, Legend, Filler, Title);

const options = {
  ...utils.chartOptions,
  scales: {
    y: {
      ticks: {
        callback: (value) => `${value}`,
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (tooltipItem) =>
          ` ${tooltipItem.formattedValue} - ${
            tooltipItem.formattedValue === 1 ? "order" : "orders"
          }`,
      },
    },
  },
};

const PastWeeksOrders = () => {
  const {
    data: pastWeeksOrders,
    error: pastWeeksOrdersErr,
    isLoading: isLoadingPastWeeksOrders,
    get: getPastWeeksOrders,
  } = useGetPastWeekOrders();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    getPastWeeksOrders();
  }, [getPastWeeksOrders]);

  useEffect(() => {
    if (pastWeeksOrders) {
      const data = {
        labels: [1, 2, 3, 4, 5, 6],
        datasets: [
          {
            label: "Past 6 Weeks Orders",
            data: [0, 0, 0, 0, 0, 0, 0],
            backgroundColor: "rgba(155,204, 244, 0.7)",
          },
        ],
      };
      if (pastWeeksOrders.rows && pastWeeksOrders.rows.length) {
        data.labels = pastWeeksOrders.rows.map(
          (orders) => `${orders.startWeek} - ${orders.endWeek}`
        );
        data.datasets[0].data = pastWeeksOrders.rows.map(
          (orders) => orders.total
        );
      }
      setData(data);
    }
  }, [pastWeeksOrders]);

  useEffect(() => {
    if (pastWeeksOrdersErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [pastWeeksOrdersErr]);

  return (
    <div className="h-100">
      {isLoadingPastWeeksOrders ? (
        <Container className="my-7">
          <Loader size="sm"/>
        </Container>
      ) : (
        <Bar options={options} data={data} />
      )}
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default PastWeeksOrders;
