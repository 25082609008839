import React, { useEffect, useState } from "react";

import { useGetQuotesPerWeek } from "../../../api/Quotes.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Card, CardBody, CardSubtitle } from "reactstrap";
import moment from "moment";

const QuotesPerWeek = () => {
  const {
    data: quotes,
    error: quotesErr,
    isLoading: isLoadingQuotes,
    get: getQuotesPerWeek,
  } = useGetQuotesPerWeek();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  
  const [data, setData] = useState({
    count: 0,
    rows: [],
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    getQuotesPerWeek();
  }, [getQuotesPerWeek]);

  useEffect(() => {
    if (quotes) {
      setData(quotes);
    }
  }, [quotes]);

  useEffect(() => {
    if (quotesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an getting quotes.",
      });
    }
  }, [quotesErr]);

  return (
    <div className="col-4">
      <div className="pl-0">
        <Card className="card-rounded">
          <CardBody className="pl-4">
          {isLoadingQuotes ? (
            <Loader size="sm"/>
          ) : (
            <div>
              <h1 className="font-weight-bold display-4">{data.count}</h1>
              <CardSubtitle className="">
                New Quotes {`from ${data.startDate} to ${data.endDate}`}
                <p className="mb-0 text-muted">
                  <small>
                    {data.lastUpdate ? `(Last Updated: ${moment(data.lastUpdate).format("MM/DD/YY LT")})`: ''}
                  </small>
                </p>
              </CardSubtitle>
            </div>
          )}
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default QuotesPerWeek;
