import { useApiGet } from "./Api.hooks";

const endpoint = `/api/sales-person`;

export const useGetTopSalesPersonByWeek = () => {
  return useApiGet(`${endpoint}/per-week`);
};

export const useGetTopSalesPersonByMonth = () => {
  return useApiGet(`${endpoint}/per-month`);
};

export const useGetPastWeekSales = () => {
  return useApiGet(`${endpoint}/past-6-weeks`);
};
