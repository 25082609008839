import React, { useEffect, useState } from "react";

import { useGetEmployees } from "../../../api/Employees.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import moment from "moment";

import { Card, CardBody, CardText } from "reactstrap";

const TopPerformers = () => {
  const {
    data: employees,
    error: employeesErr,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ employees: [] });

  useEffect(() => {
    getEmployees({
      sortBy: "productionPercent",
      direction: "desc",
    });
  }, [getEmployees]);

  useEffect(() => {
    if (employees) {
      const data = {
        employees: employees.data.map((employee) => ({
          name: employee.name,
          percent: employee.productionPercent,
        })),
        lastUpdate: employees.lastUpdate
      };
      setData(data);
    }
  }, [employees]);

  useEffect(() => {
    if (employeesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [employeesErr]);

  return (
    <div>
      <div>
        <h3 className="pl-2">Team Members Tracking Time</h3>
        <Card className="card-rounded w-100 mt-3">
          {
            isLoadingEmployees ? (
              <Loader />
            ) : (
              <div>
                <CardBody className="pb-3 pl-3 pt-2">
                  <span className="text-muted">
                    <small>
                      {data.lastUpdate ? `(Last Updated: ${moment(data.lastUpdate).format("MM/DD/YY LT")})`: ''}
                    </small>
                  </span>
                </CardBody>
                {data.employees.map((employee, index) => (
                  <div
                    key={"TP-" + index}
                    className={
                      !index ? "px-3 py-2 pr-2" : "border-top px-3 py-2 pr-2"
                    }
                  >
                    <CardText
                      key={"TP-" + index + "name"}
                      className="py-2 d-inline text-truncate"
                    >
                      {employee.name}
                    </CardText>
                    <CardText
                      key={"TP-" + index + "percent"}
                      className="d-inline float-right"
                    >
                      {employee.percent || 0}%
                    </CardText>
                  </div>
                ))}
              </div>
            )
          }
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default TopPerformers;
