import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../providers/authProvider";
import { LoginForm } from "./LoginForm";

const USER_ROLE_MANAGER = 2;
const USER_ROLE_SHOPFLOOR = 3;
const USER_ROLES_NOT_EXECUTIVE = [USER_ROLE_MANAGER, USER_ROLE_SHOPFLOOR]

const redirectTo = (location, user) => {
  if (location.search) {
    const params = new URLSearchParams(location.search);
    return <Navigate to={params.get("returnURL")} />;
  }
  if (USER_ROLES_NOT_EXECUTIVE.includes(user.userRoleId)) {
    return <Navigate to={`/dashboards`} />;
  }
  return <Navigate to={`/executive-dashboards/sales-by-salesperson`} />;
};

const SignIn = () => {
  const location = useLocation();
  const [authContext] = useAuth();

  if (!authContext.currentUser) {
    return <LoginForm />;
  }

  return redirectTo(location, authContext.currentUser);
};

export default SignIn;
