// Auth
import SignIn from "../pages/auth/signIn/SignIn";
import Home from "../pages/auth/Home";

import {
  faUsers,
  faChartBar,
  faChartLine,
  faUserClock,
  faBriefcase,
  faChartArea,
  faUsersCog,
} from "@fortawesome/free-solid-svg-icons";

import Users from "../pages/admin/Users";
import Dashboards from "../pages/admin/Dashboards";
import Employees from "../pages/admin/Employees";
import Productivity from "../components/dashboards/Productivity";
import SalesPerson from "../components/dashboards/SalesPerson";
import Finance from "../components/dashboards/Finance";
import Sales from "../components/dashboards/Sales";
import Time from "../components/dashboards/Time";

const USER_ROLE_EXECUTIVE = 1;
const USER_ROLE_MANAGER = 2;
const USER_ROLE_SHOPFLOOR = 3;

// Routes
export const adminRoutes = [
  {
    path: "/",
    name: "Home",
    sidebar: false,
    roles: [USER_ROLE_MANAGER, USER_ROLE_EXECUTIVE, USER_ROLE_SHOPFLOOR],
    component: Home,
  },
  {
    path: "/executive-dashboards",
    name: "Dashboards",
    icon: faChartBar,
    sidebar: true,
    roles: [USER_ROLE_EXECUTIVE],
    entries: [
      {
        icon: faChartArea,
        name: "Sales By Salesperson",
        path: `/executive-dashboards/sales-by-salesperson`,
        sidebar: true,
        component: SalesPerson,
      },
      {
        icon: faChartBar,
        name: "Finance Dashboard",
        path: `/executive-dashboards/finance`,
        sidebar: true,
        component: Finance,
      },
      {
        icon: faBriefcase,
        name: "Jobs Dashboard",
        path: `/executive-dashboards/productivity`,
        sidebar: true,
        component: Productivity,
      },
      {
        icon: faUserClock,
        name: "Team Dashboard",
        path: `/executive-dashboards/time`,
        sidebar: true,
        component: Time,
      },
      {
        icon: faChartLine,
        name: "Sales Dashboard",
        path: `/executive-dashboards/sales`,
        sidebar: true,
        component: Sales,
      },
    ],
  },
  {
    path: "/dashboards",
    name: "Dashboards",
    icon: faChartBar,
    sidebar: true,
    roles: [USER_ROLE_MANAGER, USER_ROLE_SHOPFLOOR],
    component: Dashboards,
  },
  {
    path: "/admin/users",
    name: "Users",
    icon: faUsers,
    sidebar: true,
    roles: [USER_ROLE_EXECUTIVE],
    component: Users,
  },
  {
    path: "/admin/employees",
    name: "Employees",
    icon: faUsersCog,
    sidebar: true,
    roles: [USER_ROLE_EXECUTIVE],
    component: Employees,
  },
];

export const authRoutes = [
  {
    path: "/auth/sign-in",
    name: "Login",
    component: SignIn,
  },
];

export const publicRoutes = [
  {
    path: "/dashboard/productivity",
    name: "Productivity",
    component: Productivity,
  },
  {
    path: "/dashboard/team-tracking",
    name: "Team tracking",
    component: Time,
  },
  {
    path: "/dashboard/sales",
    name: "Sales",
    component: Sales,
  },
];
