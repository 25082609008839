import React, { useEffect, useState } from "react";

import { useGetJobsCompletedThisWeek } from "../../../api/Jobs.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import moment from "moment";

import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { utils } from "../../../utils/utils";

const JobsCompletedThisWeek = () => {
  const {
    data: jobs,
    error: jobsErr,
    isLoading: isLoadingJobs,
    get: getJobsCompletedThisWeek,
  } = useGetJobsCompletedThisWeek();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ count: 0, rows: [] });

  useEffect(() => {
    getJobsCompletedThisWeek();
  }, [getJobsCompletedThisWeek]);

  useEffect(() => {
    if (jobs && jobs.count) {
      setData(jobs);
    }
  }, [jobs]);

  useEffect(() => {
    if (jobsErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [jobsErr]);

  return (
    <div className="col">
      <div className="w-100 d-flex flex-column">
        <Card className="card-rounded">
          <CardBody className="pl-4">
            {
              isLoadingJobs ? (
                <Loader size="sm"/>
              ) : (
                <div>
                  <h1 className="font-weight-bold display-4 pl-2">{data.count}</h1>
                  <CardSubtitle className="pl-2">Completed This Week</CardSubtitle>
                </div>
              )
            }
          </CardBody>
        </Card>
        <Card className="card-rounded">
          <CardBody className="pb-3 pl-3 pt-2">
            {
              isLoadingJobs ? (
                <Loader size="sm"/>
              ) : (
                <div>
                  <CardTitle className="mb-2 text-md text-center">
                    Recently Completed
                  </CardTitle>
                  <ListGroup className="col-12 p-0 text-center small">
                    <ListGroupItem
                      className="py-2 border-bottom font-weight-bold text-body bg-lighter d-flex text-muted"
                      tag="div"
                    >
                      <div className="col-3">Job#</div>
                      <div className="col-3">Customer Code</div>
                      <div className="col-3">Amount</div>
                      <div className="col-3">Date</div>
                    </ListGroupItem>
                    {data.rows.map((job, index) => (
                      <ListGroupItem
                        key={index}
                        className="py-2 border-bottom text-body d-flex"
                        tag="div"
                      >
                        <div className="col-3">{job.order ? job.order.number : job.number}</div>
                        <div className="col-3">{utils.getJobCustomerCode(job)}</div>
                        <div className="col-3">
                          {utils.formatCurrency(job.order?.total || 0)}
                        </div>
                        <div className="col-3">
                          {moment(job.date).format(utils.dateFormat)}
                        </div>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
              )
            }
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default JobsCompletedThisWeek;
