import { useApiGet } from "./Api.hooks";

const endpoint = `/api/finance`;

export const useGetOutstandingAPTotal = () => {
  return useApiGet(`${endpoint}/outstanding-ap-total`);
};

export const useGetOutstandingARTotal = () => {
  return useApiGet(`${endpoint}/outstanding-ar-total`);
};

export const useGetCashBalanceTotal = () => {
  return useApiGet(`${endpoint}/cash-balance-total`);
};

export const useGetProjectedCashBalanceTotal = () => {
  return useApiGet(`${endpoint}/projected-cash-balance-total`);
};
