import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AdminLayout from "../layouts/Admin";
import AuthLayout from "../layouts/Auth";
import DefaultLayout from "../layouts/Default";

import Page404 from "../pages/auth/Page404";

import { authRoutes, adminRoutes, publicRoutes } from ".";
import Page500 from "../pages/auth/Page500";
import Page401 from "../pages/auth/Page401";

const CustomRoutes = () => {
  return (
    <Router>
      <Routes>
        {authRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            element={
              <AuthLayout>
                <route.component />
              </AuthLayout>
            }
          />
        ))}
        {adminRoutes.flatMap((route, index) =>
          route.entries
            ? route.entries.map((entry, index) => (
                <Route
                  key={index}
                  path={entry.path}
                  exact
                  element={
                    <AdminLayout {...entry}>
                      <entry.component />
                    </AdminLayout>
                  }
                />
              ))
            : [
                <Route
                  key={index}
                  path={route.path}
                  exact
                  element={
                    <AdminLayout {...route}>
                      <route.component />
                    </AdminLayout>
                  }
                />,
              ]
        )}
        {publicRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact
              element={
                <DefaultLayout {...route}>
                  <route.component />
                </DefaultLayout>
              }
            />
          );
        })}
        <Route
          path={"/error/500"}
          element={
            <AuthLayout>
              <Page500 />
            </AuthLayout>
          }
        />
        <Route
          path={"/error/404"}
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
        <Route
          path={"/error/401"}
          element={
            <AuthLayout>
              <Page401 />
            </AuthLayout>
          }
        />
        <Route
          path={"*"}
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default CustomRoutes;
