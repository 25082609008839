const api_dev = "https://talladega-api.dev.belovedrobot.com/";
const api_beta = "https://talladega-api.beta.belovedrobot.com/";
const api_prod = "https://talladega-api.belovedrobot.com/";
const api_local = "http://localhost:3000/";

let apiURL;

if (process.env.REACT_APP_ENV === "production") {
  apiURL = api_prod;
} else if (process.env.REACT_APP_ENV === "development") {
  apiURL = api_dev;
} else if (process.env.REACT_APP_ENV === "beta") {
  apiURL = api_beta;
} else {
  apiURL = api_local;
}

const config = { apiURL };

export default config;
