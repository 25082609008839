import React from "react";
import { Container, Row, Col } from "reactstrap";
const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
        <Row className="text-muted">
          <Col className="text-right">
            <p className="mb-0">
              &copy; {currentYear} -{" "}
              <a
                rel="noopener noreferrer"
                href="https://benparkerco.com/"
                target="_blank"
                className="text-muted"
              >
                Talladega Casting
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
