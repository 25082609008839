import { useApiGet } from "./Api.hooks";

const endpoint = `/api/jobs`;

export const useGetJobsBehindSchedule = () => {
  return useApiGet(endpoint);
};

export const useGetTopThreeJobsBehindSchedule = () => {
  return useApiGet(`${endpoint}/top-three`);
};

export const useGetJobsStartingSoon = () => {
  return useApiGet(`${endpoint}/starting-soon`);
};

export const useGetJobsCompletedThisWeek = () => {
  return useApiGet(`${endpoint}/completed-this-week`);
};

export const useGetJobsClosedThisMonth = () => {
  return useApiGet(`${endpoint}/closed-this-month`);
};
