import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../providers/authProvider";

const Home = () => {
  const [authContext] = useAuth();
  const USER_ROLE_EXECUTIVE = 1;
  return authContext.currentUser &&
    authContext.currentUser.userRoleId === USER_ROLE_EXECUTIVE ? (
    <Navigate to="/executive-dashboards/sales-by-salesperson" />
  ) : (
    <Navigate to="/dashboards" />
  );
};

export default Home;
