import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { useUploadEmployeeUtilization } from "../../api/Imports.hooks";

import InformationModal from "../InformationModal";
import Loader from "../Loader";

const UploadEmployeeUtilizationModal = ({
  onClose,
  setMessage,
  showSuccessUpload,
}) => {
  const [file, setFile] = useState(null);

  const {
    error: sendFileErr,
    isLoading: isLoadingSendFile,
    mutate: sendData,
  } = useUploadEmployeeUtilization(file);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const defaultCloseInformationModal = () =>
    setInformationModal({ isOpen: false, title: "", body: "" });
  const [closeInformationModal, setCloseInformationModal] = useState(
    () => defaultCloseInformationModal
  );

  useEffect(() => {
    if (sendFileErr) {
      setInformationModal({
        isOpen: true,
        title: "Files Sent",
        body:
          sendFileErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [sendFileErr]);

  const upFiles = (e) => {
    setFile(e);
  };

  const doSetSubmit = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("employeeUtilization", file);
    await sendData(formdata);
    setCloseInformationModal(() => () => {
      setInformationModal({ isOpen: false, title: "", body: "" });
      onClose();
    });
    setInformationModal({
      isOpen: true,
      title: "Files Sent",
      body: "Employee utilization uploaded successfully",
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={closeInformationModal}
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSetSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Upload Employee Utilization CSV
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingSendFile ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup className="mb-0">
                  <Row>
                    <Col className="col-12">
                      <Label for="name">
                        <span>Upload Employee Utilization CSV</span>
                      </Label>
                      <Input
                        type="file"
                        name="files"
                        accept=".csv,"
                        multiple
                        required
                        onChange={(e) => {
                          upFiles(e.target.files[0]);
                        }}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Close
              </Button>
              <Button color={"primary"} type="submit">
                Send
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UploadEmployeeUtilizationModal;
