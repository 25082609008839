import React, { useEffect, useState } from "react";
import { useGetOutstandingAPTotal } from "../../../api/Finance.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Card, CardBody, CardSubtitle } from "reactstrap";

const formatter = new Intl.NumberFormat();

const OutstandingAP = () => {
  const {
    data: outstandingAPTotal,
    error: outstandingAPErr,
    isLoading: isLoadingOutstandingAP,
    get: getOutstandingAPTotal,
  } = useGetOutstandingAPTotal();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ total: 0 });

  useEffect(() => {
    getOutstandingAPTotal();
  }, [getOutstandingAPTotal]);

  useEffect(() => {
    if (outstandingAPTotal) {
      setData(outstandingAPTotal);
    }
  }, [outstandingAPTotal]);

  useEffect(() => {
    if (outstandingAPErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [outstandingAPErr]);

  return (
    <div className="col-6">
      {isLoadingOutstandingAP ? (
        <Loader />
      ) : (
        <div>
          <Card className="card-rounded">
            <CardBody className="pl-4">
              <h1 className="font-weight-bold display-5">{`$${formatter.format(
                data.total
              )}`}</h1>
              <CardSubtitle className="">Outstanding AP Total</CardSubtitle>
            </CardBody>
          </Card>
        </div>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default OutstandingAP;
