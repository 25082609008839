import React, { useEffect, useState } from "react";

import { useGetOrdersVsQuota } from "../../../api/Orders.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Card, CardBody, CardSubtitle } from "reactstrap";

const OrdersVsQuota = () => {
  const {
    data: ordersVsQuota,
    error: ordersVsQuotaErr,
    isLoading: isLoadingOrdersVsQuota,
    get: getOrdersVsQuota,
  } = useGetOrdersVsQuota();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ ordersTotal: 0, quota: 0 });

  useEffect(() => {
    getOrdersVsQuota();
  }, [getOrdersVsQuota]);

  useEffect(() => {
    if (ordersVsQuota) {
      setData(ordersVsQuota);
    }
  }, [ordersVsQuota]);

  useEffect(() => {
    if (ordersVsQuotaErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [ordersVsQuotaErr]);

  return (
    <div className="col-4">
      <div className="pl-0">
        <Card className="card-rounded">
          <CardBody className="pl-4">
          {
            isLoadingOrdersVsQuota ? (
              <Loader size="sm"/>
            ) : (
              <div>
                <h1 className="font-weight-bold display-4">{`${data.ordersTotal} / ${data.quota}`}</h1>
                <CardSubtitle className="">Orders VS Quota</CardSubtitle>
              </div>
            )
          }
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default OrdersVsQuota;
