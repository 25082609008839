import React, { useEffect, useState } from "react";

import { useGetReworksFromLastSixWeeks } from "../../../api/Reworks.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import moment from "moment";

import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { utils } from "../../../utils/utils";

const ReworksFromLastSixWeeks = () => {
  const {
    data: reworks,
    error: reworksErr,
    isLoading: isLoadingReworks,
    get: getReworksFromLastSixWeeks,
  } = useGetReworksFromLastSixWeeks();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ count: 0, rows: [] });

  useEffect(() => {
    getReworksFromLastSixWeeks();
  }, [getReworksFromLastSixWeeks]);

  useEffect(() => {
    if (reworks && reworks.count) {
      setData(reworks);
    }
  }, [reworks]);

  useEffect(() => {
    if (reworksErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [reworksErr]);

  return (
    <div className="col">
      <div className="w-100 d-flex flex-column">
        <Card className="card-rounded">
          <CardBody className="pl-4">
            {
              isLoadingReworks ? (
                <Loader size="sm"/>
              ) : (
                <div>
                  <h1 className="font-weight-bold display-4 pl-2">{data.count}</h1>
                  <CardSubtitle className="pl-2">Reworks From Last Four Weeks</CardSubtitle>
                </div>
              )
            }
          </CardBody>
        </Card>
        <Card className="card-rounded">
          <CardBody className="pb-3 pl-3 pt-2">
            {
              isLoadingReworks ? (
                <Loader size="sm"/>
              ) : (
                <div>
                  <CardTitle className="mb-2 text-md text-center">
                    Reworks list
                  </CardTitle>
                  <ListGroup className="col-12 p-0 text-center small">
                    <ListGroupItem
                      className="py-2 border-bottom font-weight-bold text-body bg-lighter d-flex text-muted"
                      tag="div"
                    >
                      <div className="col-2">Job #</div>
                      <div className="col-2">Status</div>
                      <div className="col-8">Description</div>
                    </ListGroupItem>
                    {data.rows.map((rework, index) => (
                      <ListGroupItem
                        key={index}
                        className="py-2 border-bottom text-body d-flex  pr-0"
                        tag="div"
                      >
                        <div className="col-2 d-flex  justify-content-center align-items-center">
                          {rework.jobNumber || "-"}
                        </div>
                        <div className="col-2  d-flex justify-content-center align-items-center">
                          {rework.status || "-"}
                        </div>
                        <div className="col-8 text-left pr-0">
                          <p className="mb-0 pb-2 mr-4">{rework.description || "-"}</p>
                          <p className="m-0 border-top"></p>
                          <p className="m-0 pt-2 mr-4">{rework.partDescription || "-"}</p>
                        </div>
                        
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </div>
              )
            }
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default ReworksFromLastSixWeeks;
