import React from "react";
import TopSalesPersonByWeek from "./salesperson/TopSalesPersonByWeek";
import TopSalesPersonByMonth from "./salesperson/TopSalesPersonByMonth";
import PastWeeksSales from "./salesperson/PastWeeksSales";
import AccountReceivable from "./salesperson/AccountReceivable";

const SalesPerson = () => {
  return (
    <div className="h-100 d-flex flex-column">
      <div className="w-100  overflow-auto">
        <div className="d-flex flex-wrap" style={{ minWidth: 1600 }}>
          <TopSalesPersonByWeek />
          <TopSalesPersonByMonth />
          <AccountReceivable />
        </div>
      </div>
      <div className="flex-grow-1">
        <PastWeeksSales />
      </div>
    </div>
  );
};

export default SalesPerson;
