import React from "react";
import OrdersPerWeek from "../../components/dashboards/sales/OrdersPerWeek";
import QuotesPerWeek from "../../components/dashboards/sales/QuotesPerWeek";
import TotalPipeline from "../../components/dashboards/sales/TotalPipeline";
import OrdersVsQuota from "../../components/dashboards/sales/OrdersVsQuota";
import ConversionRate from "../../components/dashboards/sales/ConversionRate";
import PastWeeksOrders from "../../components/dashboards/sales/PastWeeksOrders";
import { Row } from "reactstrap";

const Sales = () => {
  return (
    <div className="h-100 d-flex flex-column">
      <h2 className="display-5">Orders</h2>
      <div className="w-100 overflow-auto mt-2">
        <Row className="d-flex flex-wrap">
          <OrdersPerWeek />
          <OrdersVsQuota />
          <QuotesPerWeek />
          <ConversionRate />
          <TotalPipeline />
        </Row>
      </div>
      <div className="flex-grow-1">
        <PastWeeksOrders />
      </div>
    </div>
  );
};

export default Sales;
