import React, { useEffect, useState } from "react";

import { useGetOrdersPerWeek } from "../../../api/Orders.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Card, CardBody, CardSubtitle } from "reactstrap";

const OrdersPerWeek = () => {
  const {
    data: orders,
    error: ordersErr,
    isLoading: isLoadingOrders,
    get: getOrdersPerWeek,
  } = useGetOrdersPerWeek();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ count: 0 });

  useEffect(() => {
    getOrdersPerWeek();
  }, [getOrdersPerWeek]);

  useEffect(() => {
    if (orders) {
      setData(orders);
    }
  }, [orders]);

  useEffect(() => {
    if (ordersErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [ordersErr]);

  return (
    <div className="col-4">
      <div className="pl-0">
        <Card className="card-rounded">
          <CardBody className="pl-4">
            {
              isLoadingOrders ? (
                <Loader size="sm"/>
              ) : (
                <div>
                  <h1 className="font-weight-bold display-4">{data.count}</h1>
                  <CardSubtitle className="">New Orders This Week</CardSubtitle>
                </div>
              )
            }
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default OrdersPerWeek;
