import React, { useEffect, useState } from "react";
import { useGetEmployees } from "../../../api/Employees.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import moment from "moment";

import { Card, CardBody, CardSubtitle, CardText } from "reactstrap";

const today = moment();

const TeamPercentTrackingHours = () => {
  const {
    data: employees,
    error: employeesErr,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ percent: 0 });

  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  useEffect(() => {
    if (employees) {
      const data = {
        percent: employees.data.filter((employee) =>
          employee.clocks.find((clock) =>
            moment(clock.date).isSame(today, "date")
          )
        ).length,
      };
      setData(data);
    }
  }, [employees]);

  useEffect(() => {
    if (employeesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [employeesErr]);

  return (
    <div>
      <Card className="rounded-lg border border-2 border-primary">
        <CardBody className="p-3 pl-4 pb-1">
        {
          isLoadingEmployees ? (
            <Loader size="sm"/>
          ) : (
            <div>
              <CardSubtitle className="display-1 text-primary d-inline-block font-weight-bold pl-2">
                {data.percent}%
              </CardSubtitle>
              <CardText className="h3 font-weight-normal d-inline-block pl-3 text-primary">
                Team Tracking Hours Today
              </CardText>
            </div>
          )
        }
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default TeamPercentTrackingHours;
