import { useCallback } from "react";
import { useApiGet, useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/orders`;
const ordersPerWeekEndpoint = `${endpoint}/orders-per-week`;

export const useGetOrders = () => {
  return useApiGet(endpoint);
};

export const useGetOrdersPerWeek = () => {
  return useApiGet(ordersPerWeekEndpoint);
};

export const useGetOrdersVsQuota = () => {
  return useApiGet(`${endpoint}/orders-vs-quota`);
};

export const useGetConversionRate = () => {
  return useApiGet(`${endpoint}/conversion-rate`);
};

export const useGetPastWeekOrders = () => {
  return useApiGet(`${endpoint}/past-6-weeks`);
};

export const useCreateOrder = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateOrder = (orderId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/${orderId}`
  );

  const update = useCallback(
    async (order) => {
      await mutate(order);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteOrder = (orderId) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/${orderId}`
  );

  const deleteOrder = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, deleteOrder };
};
