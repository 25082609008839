import {
  faCompressAlt,
  faExpandAlt,
  faPauseCircle,
  faPlayCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ACTIONS, useSidebar } from "../../providers/sidebarProvider";
import Productivity from "../../components/dashboards/Productivity";
import Time from "../../components/dashboards/Time";
import Sales from "../../components/dashboards/Sales";
import SalesPerson from "../../components/dashboards/SalesPerson";
import Finance from "../../components/dashboards/Finance";
import { useAuth } from "../../providers/authProvider";
import { utils } from "../../utils/utils";

import { Carousel, CarouselItem, CarouselControl, Button } from "reactstrap";

const USER_ROLE_EXECUTIVE = 1;
const USER_ROLE_MANAGER = 2;

const defaultItems = [
  {
    id: 1,
    component: <div></div>,
    title: "Loading dashboards...",
  },
];

const executiveItems = [
  {
    id: 1,
    component: <SalesPerson />,
    title: "Sales By Salesperson",
  },
  {
    id: 2,
    component: <Finance />,
    title: "Finance Dashboard",
  },
];

const shopfloorItems = [
  {
    id: 1,
    component: <Productivity />,
    title: "Jobs Dashboard",
  },
  {
    id: 2,
    component: <Time />,
    title: "Team Dashboard",
  },
];

const managerItems = [
  ...shopfloorItems,
  {
    id: 3,
    component: <Sales />,
    title: "Sales Dashboard",
  },
];

const Dashboards = () => {
  const [sidebarContext, setSidebarContext] = useSidebar();
  const location = useLocation();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState();
  const [title, setTitle] = useState("");
  const [autoplay, setAutoplay] = useState(true);
  const [autoplayTimeout, setAutoplayTimeout] = useState(true);
  const [authContext] = useAuth();
  const [items, setItems] = useState(defaultItems);

  const isPublicKiosk = location.pathname.indexOf("/public") > -1;

  useEffect(() => {
    if (authContext.currentUser.userRoleId === USER_ROLE_EXECUTIVE) {
      setItems(executiveItems);
    } else if (authContext.currentUser.userRoleId === USER_ROLE_MANAGER) {
      setItems(managerItems);
    }
    else{
      setItems(shopfloorItems);
    }
  }, [authContext]);

  useEffect(() => {
    if (autoplay) {
      const timeout = setTimeout(
        () => setActiveIndex((activeIndex + 1) % items.length),
        utils.slideInterval
      );
      setAutoplayTimeout(timeout);
    }
    setTitle(items[activeIndex].title);
  }, [autoplay, activeIndex, items]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  return (
    <div className="position-relative flex-grow-1 d-flex flex-column">
      <h4 className="position-absolute mt-2 w-100 text-center z-index-n1">
        {title}
      </h4>
      <div className="d-flex justify-content-end mb-5">
        <Button
          color="primary"
          onClick={() => {
            clearTimeout(autoplayTimeout);
            setAutoplay(!autoplay);
          }}
        >
          <FontAwesomeIcon
            icon={autoplay ? faPauseCircle : faPlayCircle}
            size="lg"
          />
        </Button>
        {isPublicKiosk ? (
          <Button
            className="ml-2"
            color="primary"
            onClick={() => navigate("/auth/sign-in")}
          >
            <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
          </Button>
        ) : (
          <Button
            className="ml-2"
            color="primary"
            onClick={() =>
              setSidebarContext({
                action: ACTIONS.TOGGLE,
              })
            }
          >
            <FontAwesomeIcon
              icon={sidebarContext.isOpen ? faExpandAlt : faCompressAlt}
              size="lg"
            />
          </Button>
        )}
      </div>
      <div className="flex-grow-1 d-flex flex-column">
        <Carousel
          className="flex-grow-1"
          interval={false}
          activeIndex={activeIndex}
          next={next}
          previous={previous}
        >
          {items.map((item) => {
            return (
              <CarouselItem
                key={item.id}
                className="col-12 h-100"
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
              >
                {item.component}
              </CarouselItem>
            );
          })}
          <CarouselControl
            className="justify-content-start pl-4"
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <CarouselControl
            className="justify-content-end pr-4"
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </Carousel>
      </div>
    </div>
  );
};

export default Dashboards;
