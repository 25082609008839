import React from "react";
import JobsCompletedThisWeek from "../../components/dashboards/productivity/JobsCompletedThisWeek";
import JobsClosedThisMonth from "../../components/dashboards/productivity/JobsClosedThisMonth";
import ReworksFromLastSixWeeks from "../../components/dashboards/productivity/ReworksFromLastSixWeeks";

const Productivity = () => {
  return (
    <div className=" w-100">
      <JobsClosedThisMonth />
      <div className="w-100">
        <div className="row">
          <div className="col-6">
            <JobsCompletedThisWeek />
          </div>
          <div className="col-6">
            <ReworksFromLastSixWeeks />
          </div>
          </div>
      </div>
    </div>
  );
};

export default Productivity;
