import React, { useEffect, useState } from "react";

import {
  useGetAccountsReceivable,
  useGetAccountsReceivableNinetyDays,
} from "../../../api/AccountReceivable.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { utils } from "../../../utils/utils";
import moment from "moment";

const AccountReceivable = () => {
  const {
    data: accountsReceivable,
    error: accountsReceivableErr,
    isLoading: isLoadingAccountsReceivable,
    get: getAccountsReceivable,
  } = useGetAccountsReceivable();

  const {
    data: accountsReceivableNinetyDays,
    error: accountsReceivableNinetyDaysErr,
    isLoading: isLoadingAccountsReceivableNinetyDays,
    get: getAcountsReceivableNinetyDays,
  } = useGetAccountsReceivableNinetyDays();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ count: 0 });
  const [accountsReceivableNinetyDaysData, setAccountsReceivableNinetyDays] =
    useState([]);

  useEffect(() => {
    getAccountsReceivable();
    getAcountsReceivableNinetyDays();
  }, [getAccountsReceivable, getAcountsReceivableNinetyDays]);

  useEffect(() => {
    if (accountsReceivable) {
      setData(accountsReceivable);
    }
    if (accountsReceivableNinetyDays) {
      setAccountsReceivableNinetyDays(accountsReceivableNinetyDays);
    }
  }, [accountsReceivable, accountsReceivableNinetyDays]);

  useEffect(() => {
    if (accountsReceivableErr || accountsReceivableNinetyDaysErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [accountsReceivableErr, accountsReceivableNinetyDaysErr]);

  return (
    <div className="col-4">
      <div>
        <Card className="card-rounded">
          <CardBody className="pl-4">
          {isLoadingAccountsReceivable ? (
            <Loader size="sm"/>
          ) : (
            <div>
              <h1 className="font-weight-bold display-4 pl-2">{data.count}</h1>
              <CardSubtitle className="pl-2">
                Accounts Receivable
                <span className="pl-3 text-muted">
                  <small>
                    {data.lastUpdate ? `(Last Updated: ${moment(data.lastUpdate).format("MM/DD/YY LT")})`: ''}
                  </small>
                </span>
              </CardSubtitle>
            </div>
          )}
          </CardBody>
        </Card>
        <Card className="card-rounded">
          <CardBody className="pb-3 pl-3 pt-2">
            <CardTitle className="mb-2 text-md text-center">
              Past Due 90 Days
            </CardTitle>
            {isLoadingAccountsReceivableNinetyDays ? (
              <Loader size="sm"/>
            ) : (
              <ListGroup className="col-12 p-0 text-center small">
                <ListGroupItem
                  className="py-2 border-bottom font-weight-bold text-body bg-lighter d-flex text-muted"
                  tag="div"
                >
                  <div className="col-6">Customer</div>
                  <div className="col-6">Total</div>
                </ListGroupItem>
                {accountsReceivableNinetyDaysData.map(
                  (accountsReceivable, index) => (
                    <ListGroupItem
                      key={index}
                      className="py-2 border-bottom text-body d-flex"
                      tag="div"
                    >
                      <div className="col-6">
                        {accountsReceivable.customer.name}
                      </div>
                      <div className="col-6">
                        {utils.formatCurrency(
                          Math.ceil(
                            accountsReceivable.ninetyDays +
                              accountsReceivable.overNinetyDays
                          ),
                          0
                        )}
                      </div>
                    </ListGroupItem>
                  )
                )}
              </ListGroup>
            )}
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default AccountReceivable;
