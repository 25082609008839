import React from "react";
import Routes from "./routes/Routes";

import { AuthProvider } from "./providers/authProvider";
import { UsersProvider } from "./providers/usersProvider";
import { SidebarProvider } from "./providers/sidebarProvider";
import { OrdersProvider } from "./providers/ordersProvider";
import { EmployeesProvider } from "./providers/employeesProvider";

class App extends React.Component {
  render() {
    return (
      <SidebarProvider>
        <AuthProvider>
          <UsersProvider>
            <OrdersProvider>
              <EmployeesProvider>
                <Routes />
              </EmployeesProvider>
            </OrdersProvider>
          </UsersProvider>
        </AuthProvider>
      </SidebarProvider>
    );
  }
}

export default App;
