import React, { useEffect, useState } from "react";

import { useGetJobsClosedThisMonth } from "../../../api/Jobs.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import moment from "moment";

import { Card, CardBody, CardSubtitle, CardText } from "reactstrap";

const JobsClosedThisMonth = () => {
  const {
    data: jobs,
    error: jobsErr,
    isLoading: isLoadingJobs,
    get: getJobsClosedThisMonth,
  } = useGetJobsClosedThisMonth();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({
    count: 0,
    month: moment().format("MMMM"),
  });

  useEffect(() => {
    getJobsClosedThisMonth();
  }, [getJobsClosedThisMonth]);

  useEffect(() => {
    if (jobs && jobs.count) {
      setData(jobs);
    }
  }, [jobs]);

  useEffect(() => {
    if (jobsErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [jobsErr]);

  return (
    <div className="w-100">
        <Card className="rounded-lg border border-2 border-primary">
          <CardBody className="p-3 pl-4 pb-1">
          {
            isLoadingJobs ? (
              <Loader size="sm"/>
            ) : (
              <div>
                <CardSubtitle className="display-1 text-primary d-inline-block font-weight-bold pl-2">
                  {data.count}
                </CardSubtitle>
                <CardText className="h3 font-weight-normal d-inline-block pl-3 text-primary">
                  Jobs Completed in {data.month}
                </CardText>
              </div>
            )
          }
          </CardBody>
        </Card>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default JobsClosedThisMonth;
