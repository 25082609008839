import React, { useEffect, useState } from "react";

import { useGetLastCompletedTasks } from "../../../api/Tasks.hooks";
import InformationModal from "../../InformationModal";
import { utils } from "../../../utils/utils";
import Loader from "../../Loader";
import moment from "moment";

import { Card, CardBody, CardText } from "reactstrap";

const CompletedTaskList = () => {
  const {
    data: lastCompletedTasks,
    error: lastCompletedTasksErr,
    isLoading: isLoadingLastCompletedtasks,
    get: getLastCompletedTasks,
  } = useGetLastCompletedTasks();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ lastCompletedTasks: [] });

  useEffect(() => {
    getLastCompletedTasks();
  }, [getLastCompletedTasks]);

  useEffect(() => {
    if (lastCompletedTasks) {
      const data = {
        lastCompletedTasks: lastCompletedTasks.data.map((employee) => ({
          name: employee.name,
          taskInfo: `${employee.tasks[0].job.number} - ${utils.formatDecimal(employee.tasks[0].hours, 1)} HRS`,
        })),
        lastUpdate: lastCompletedTasks.lastUpdate
      };
      setData(data);
    }
  }, [lastCompletedTasks]);

  useEffect(() => {
    if (lastCompletedTasksErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [lastCompletedTasksErr]);

  return (
    <div>
      <div>
        <h3 className="pl-2">Team Members Current Task</h3>
        <Card className="card-rounded w-100 mt-3">
          {
            isLoadingLastCompletedtasks ? (
              <Loader />
            ) : (
              <div>
                <CardBody className="pb-3 pl-3 pt-2">
                  <span className="text-muted">
                    <small>
                      {data.lastUpdate ? `(Last Updated: ${moment(data.lastUpdate).format("MM/DD/YY LT")})`: ''}
                    </small>
                  </span>
                </CardBody>
                {data.lastCompletedTasks.map((employee, index) => (
                  <div
                    key={"CTL-" + index}
                    className={
                      !index ? "px-3 py-2 pr-2" : "border-top px-3 py-2 pr-2"
                    }
                  >
                    <CardText
                      key={"CTL-" + index + "name"}
                      className="py-2 d-inline text-truncate"
                    >
                      {employee.name}
                    </CardText>
                    <CardText
                      key={"CTL-" + index + "taskInfo"}
                      className="d-inline float-right"
                    >
                      {employee.taskInfo || 0}
                    </CardText>
                  </div>
                ))}
              </div>
            )
          }
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default CompletedTaskList;
