import React from "react";
import { useNavigate } from "react-router";

import { Button } from "reactstrap";
import { useAuth } from "../../providers/authProvider";

const Page401 = () => {
  const navigate = useNavigate();
  const [authContext] = useAuth();
  return (
    <div className="text-center">
      <h1 className="display-1 font-weight-bold">401</h1>
      <p className="h1">You don't have permission to visit this page.</p>
      <p className="h2 font-weight-normal mt-3 mb-4">
        The page you are looking for might need permission to be visited, please
        contact your system administrator.
      </p>
      <Button
        onClick={() =>
          authContext.currentUser
            ? navigate("/orders")
            : navigate("/auth/sign-in")
        }
        color="primary"
        size="lg"
      >
        Return to website
      </Button>
    </div>
  );
};

export default Page401;
