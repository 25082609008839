import { useApiGet } from "./Api.hooks";

const endpoint = `/api/account-receivable`;

export const useGetAccountsReceivable = () => {
  return useApiGet(endpoint);
};

export const useGetAccountsReceivableNinetyDays = () => {
  return useApiGet(`${endpoint}/ninety-days`);
};
