import React, { useEffect, useState } from "react";

import { useGetTopSalesPersonByWeek } from "../../../api/SalesPerson.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { utils } from "../../../utils/utils";

const TopSalesPersonByWeek = () => {
  const {
    data: topSalesPerson,
    error: topSalesPersonErr,
    isLoading: isLoadingTopSalesPersonByWeek,
    get: getTopSalesPersonByWeek,
  } = useGetTopSalesPersonByWeek();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ count: 0, rows: [] });

  useEffect(() => {
    getTopSalesPersonByWeek();
  }, [getTopSalesPersonByWeek]);

  useEffect(() => {
    if (topSalesPerson) {
      setData(topSalesPerson);
    }
  }, [topSalesPerson]);

  useEffect(() => {
    if (topSalesPersonErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [topSalesPersonErr]);

  return (
    <div className="col-4">
      <Card className="card-rounded">
        <CardBody className="pl-4">
          { 
            isLoadingTopSalesPersonByWeek ? (
              <Loader size="sm"/>
            ) : (
              <div>
                <h1 className="font-weight-bold display-4 pl-2">{data.count}</h1>
                <CardSubtitle className="pl-2">Sales This Week</CardSubtitle>
              </div>
            )
          }
        </CardBody>
      </Card>
      <Card className="card-rounded">
        <CardBody className="pb-3 pl-3 pt-2">
          <CardTitle className="mb-2 text-md text-center">
            Top 3 Of Week
          </CardTitle>
          { 
            isLoadingTopSalesPersonByWeek ? (
              <Loader size="sm"/>
            ) : (
              <ListGroup className="col-12 p-0 text-center small">
                <ListGroupItem
                  className="py-2 border-bottom font-weight-bold text-body bg-lighter d-flex text-muted"
                  tag="div"
                >
                  <div className="col-6">Salesperson</div>
                  <div className="col-6">Sales</div>
                </ListGroupItem>
                {data.rows.map((row, index) => (
                  <ListGroupItem
                    key={index}
                    className="py-2 border-bottom text-body d-flex"
                    tag="div"
                  >
                    <div className="col-6">{row?.salesPerson?.name}</div>
                    <div className="col-6">
                      {utils.formatCurrency(row.amount)}
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            )
          }
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default TopSalesPersonByWeek;
