import { useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const endpoint = `/api/imports`;

export const useUploadEmployeeUtilization = (files) => {
  return useApiMutation(
    HttpMethod.POST,
    `${endpoint}/employee-utilization`,
    files
  );
};

export const useUploadNewQuotesThisWeek = (files) => {
  return useApiMutation(
    HttpMethod.POST,
    `${endpoint}/new-quotes-this-week`,
    files
  );
};

export const useUploadOrderEntrySummary = (files) => {
  return useApiMutation(
    HttpMethod.POST,
    `${endpoint}/order-entry-summary`,
    files
  );
};

export const useUploadAccountReceivable = (files) => {
  return useApiMutation(
    HttpMethod.POST,
    `${endpoint}/account-receivable`,
    files
  );
};
