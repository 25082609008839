import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { useUpdateUser, useCreateUser } from "../../../api/Users.hooks";

import InformationModal from "../../InformationModal";

import Loader from "../../Loader";

const EXECUTIVE_ROLE = 1;
const MANAGER_ROLE = 2;
const SHOPFLOOR_ROLE = 3;

const ROLES = [
  {
    label: "Executive",
    id: EXECUTIVE_ROLE,
  },
  {
    label: "Manager",
    id: MANAGER_ROLE,
  },
  {
    label: "Shopfloor",
    id: SHOPFLOOR_ROLE,
  },
];

const UserModal = ({ user = {}, onClose, onSubmit }) => {
  const [currentUser, setCurrentUser] = useState(user);

  const {
    error: updateUserErr,
    isLoading: isLoadingUpdateUser,
    update: updateUser,
    data: updateUserData,
  } = useUpdateUser(currentUser.id);

  const {
    error: createUserErr,
    isLoading: isLoadingCreateUser,
    mutate: createUser,
    data: createUserData,
  } = useCreateUser();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    if (updateUserErr) {
      setInformationModal({
        isOpen: true,
        title: "Update User",
        body:
          updateUserErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [updateUserErr]);

  useEffect(() => {
    if (updateUserData) {
      onSubmit();
    }
  }, [updateUserData, onSubmit]);

  useEffect(() => {
    if (createUserData) {
      onSubmit();
    }
  }, [createUserData, onSubmit]);

  useEffect(() => {
    if (createUserErr) {
      setInformationModal({
        isOpen: true,
        title: "Create User",
        body:
          createUserErr?.response?.data[0].msg ||
          "There was an error with your request.",
      });
    }
  }, [createUserErr]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (currentUser.id) {
      await updateUser(currentUser);
    } else {
      await createUser(currentUser);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {currentUser.id ? "Edit" : "Create"} User
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoadingCreateUser || isLoadingUpdateUser ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label for="firstName">
                    <span>First Name</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="text"
                    name="name"
                    value={currentUser.firstName || ""}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        firstName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="lastName">
                    <span>Last Name</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="text"
                    name="name"
                    value={currentUser.lastName || ""}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        lastName: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">
                    <span>Email</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    maxLength="50"
                    type="email"
                    name="email"
                    value={currentUser.email || ""}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        email: e.target.value.trim(),
                      })
                    }
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="email">
                    <span>Password</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    required={!currentUser.id}
                    autoComplete="new-password"
                    maxLength="50"
                    type="password"
                    name="password"
                    value={currentUser.password || ""}
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        password: e.target.value.trim(),
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="role">
                    <span>Role</span>
                    <span className="text-danger ml-2">*</span>
                  </Label>
                  <Input
                    type="select"
                    name="roleSelect"
                    id="roleSelect"
                    onChange={(e) =>
                      setCurrentUser({
                        ...currentUser,
                        userRoleId: parseInt(e.target.value),
                      })
                    }
                    value={currentUser.userRoleId}
                    required
                  >
                    <option value={""}>Select a Role</option>
                    {ROLES.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;
