import React, { useEffect, useState } from "react";

import { useGetTopSalesPersonByMonth } from "../../../api/SalesPerson.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

const TopSalesPersonByMonth = () => {
  const {
    data: topSalesPerson,
    error: topSalesPersonErr,
    isLoading: isLoadingTopSalesPersonByMonth,
    get: getTopSalesPersonByMonth,
  } = useGetTopSalesPersonByMonth();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ count: 0, rows: [] });

  useEffect(() => {
    getTopSalesPersonByMonth();
  }, [getTopSalesPersonByMonth]);

  useEffect(() => {
    if (topSalesPerson) {
      setData(topSalesPerson);
    }
  }, [topSalesPerson]);

  useEffect(() => {
    if (topSalesPersonErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [topSalesPersonErr]);

  return (
    <div className="col-4">
      <div>
        <Card className="card-rounded">
          <CardBody className="pl-4">
            {  isLoadingTopSalesPersonByMonth ? (
              <Loader size="sm"/>
            ) : (
              <div>
                <h1 className="font-weight-bold display-4 pl-2">{data.count}</h1>
                <CardSubtitle className="pl-2">Sales This Month</CardSubtitle>
              </div>
            )}
          </CardBody>
        </Card>
        <Card className="card-rounded">
          <CardBody className="pb-3 pl-3 pt-2">
            <CardTitle className="mb-2 text-md text-center">
              Top 3 Of Month
            </CardTitle>
            {  isLoadingTopSalesPersonByMonth ? (
              <Loader size="sm"/>
            ) : (
              <ListGroup className="col-12 p-0 text-center small">
                <ListGroupItem
                  className="py-2 border-bottom font-weight-bold text-body bg-lighter d-flex text-muted"
                  tag="div"
                >
                  <div className="col-6">Salesperson</div>
                  <div className="col-6">Sales</div>
                </ListGroupItem>
                {data.rows.map((salesperson, index) => (
                  <ListGroupItem
                    key={index}
                    className="py-2 border-bottom text-body d-flex"
                    tag="div"
                  >
                    <div className="col-6">{salesperson.name}</div>
                    <div className="col-6">{salesperson.quantity}</div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            )}
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default TopSalesPersonByMonth;
