import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { utils } from "../../utils/utils";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Table,
} from "reactstrap";

import InformationModal from "../../components/InformationModal";

import { ACTIONS, useEmployees } from "../../providers/employeesProvider";

import { useGetEmployees } from "../../api/Employees.hooks";
import { faClock, faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";

const Employees = () => {
  const [employeesContext, setEmployeesContext] = useEmployees();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          employeesContext.sortBy === sortBy.id &&
          employeesContext.direction === direction
        ) {
          return;
        }
        setEmployeesContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setEmployeesContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [setEmployeesContext, employeesContext.direction, employeesContext.sortBy]
  );

  const {
    data: employees,
    error: employeesErr,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSeeClocks = (employee) =>
    setInformationModal({
      isOpen: true,
      title: "Employee Clocks",
      rawBody: true,
      body: (
        <Table className="border small mb-0">
          <thead className="text-muted bg-light">
            <tr>
              <th>Date</th>
              <th>Shift</th>
            </tr>
          </thead>
          <tbody>
            {employee.clocks.length ? (
              employee.clocks.map((clock) => (
                <tr key={clock.id}>
                  <td>{moment(clock.date).format(utils.dateFormat)}</td>
                  <td>{clock.shift}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No clocks to show</td>
              </tr>
            )}
          </tbody>
        </Table>
      ),
    });

  const setEmployeesContextCb = useCallback(
    (data) => setEmployeesContext(data),
    [setEmployeesContext]
  );

  useEffect(() => {
    getEmployees({
      search: employeesContext.search,
      page: employeesContext.page - 1,
      pageSize: employeesContext.sizePerPage,
      sortBy: employeesContext.sortBy,
      direction: employeesContext.direction,
    });
  }, [
    getEmployees,
    employeesContext.direction,
    employeesContext.search,
    employeesContext.sortBy,
    employeesContext.page,
    employeesContext.sizePerPage,
    employeesContext.refresh,
  ]);

  useEffect(() => {
    if (employees) {
      setEmployeesContextCb({
        action: ACTIONS.GET_EMPLOYEES_SUCCESS,
        payload: { employees },
      });
    }
  }, [employees, setEmployeesContextCb]);

  useEffect(() => {
    if (employeesErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [employeesErr]);

  const columns = [
    {
      accessor: "name",
      Header: "Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { name } = rowData.row.original;
        return name || "-";
      },
    },
    {
      accessor: "number",
      Header: "Number",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { number } = rowData.row.original;
        return number || "-";
      },
    },
    {
      accessor: "departmentCode",
      Header: "Department Code",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { departmentCode } = rowData.row.original;
        return departmentCode || "-";
      },
    },
    {
      accessor: "productionPercent",
      Header: "Production Percent",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { productionPercent } = rowData.row.original;
        return productionPercent ? `${productionPercent}%` : "-";
      },
    },
    {
      accessor: "id",
      Header: "Actions",
      disableSortBy: true,
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => (
        <div className="d-flex justify-content-center">
          <Button
            size="sm"
            className="rounded d-flex align-items-center"
            color="warning"
            onClick={() => onSeeClocks(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faClock} className="mr-1" size="lg" />
            <span>See Clocks</span>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Container fluid>
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={employeesContext.employees.data || []}
          pageSize={employeesContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: employeesContext.sortBy,
            direction: employeesContext.direction,
          }}
        >
          <Card className="mb-3 w-100">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Employees</h3>
                <small className="text-muted ml-2 pt-1">
                  ({employeesContext.employees.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={employeesContext.search}
                    onChange={(evt) =>
                      setEmployeesContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setEmployeesContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <AdvanceTable
                table
                isLoading={isLoadingEmployees}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={employeesContext.employees.count}
                pageCount={employeesContext.employees.totalPages}
                currentPage={employeesContext.page - 1}
                onPageChange={(page) =>
                  setEmployeesContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={employeesContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setEmployeesContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </Container>
  );
};

export default Employees;
