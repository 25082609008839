import React from "react";

import { useAuth } from "../../../providers/authProvider";
import placeholder from "../../../assets/img/placeholder.png";

const SideBarUser = () => {
  const [authContext] = useAuth();

  return (
    <div className="sidebar-user p-3 d-flex flex-column align-items-center justify-content-center">
      <div
        className="rounded-circle img-thumbnail d-flex align-items-center justify-content-center"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${placeholder})`,
          width: "96px",
          height: "96px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <span className="text-muted mt-1 font-500">
        {authContext.currentUser?.name || "User"}
      </span>
      <small className="text-muted text-underline mb-1">
        {authContext.currentUser?.role?.name || "-"}
      </small>
    </div>
  );
};

export default SideBarUser;
