import React, { useEffect, useState } from "react";
import { useGetProjectedCashBalanceTotal } from "../../../api/Finance.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Card, CardBody, CardSubtitle } from "reactstrap";

const formatter = new Intl.NumberFormat();

const ProjectedCashBalance = () => {
  const {
    data: projectedCashBalanceTotal,
    error: projectedCashBalanceErr,
    isLoading: isLoadingProjectedCashBalance,
    get: getProjectedCashBalanceTotal,
  } = useGetProjectedCashBalanceTotal();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ total: 0 });

  useEffect(() => {
    getProjectedCashBalanceTotal();
  }, [getProjectedCashBalanceTotal]);

  useEffect(() => {
    if (projectedCashBalanceTotal) {
      setData(projectedCashBalanceTotal);
    }
  }, [projectedCashBalanceTotal]);

  useEffect(() => {
    if (projectedCashBalanceErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [projectedCashBalanceErr]);

  return (
    <div className="col-6">
      {isLoadingProjectedCashBalance ? (
        <Loader />
      ) : (
        <div>
          <Card className="card-rounded">
            <CardBody className="pl-4">
              <h1 className="font-weight-bold display-5">{`$${formatter.format(
                data.total
              )}`}</h1>
              <CardSubtitle className="">
                Projected Cash Balance Total
              </CardSubtitle>
            </CardBody>
          </Card>
        </div>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default ProjectedCashBalance;
