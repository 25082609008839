import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  Badge,
} from "reactstrap";

import { ACTIONS, useUsers } from "../../providers/usersProvider";

import {
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserModal from "../../components/admin/users/UserModal";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import InformationModal from "../../components/InformationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useAuth } from "../../providers/authProvider";

import { useGetUsers, useDeleteUser } from "../../api/Users.hooks";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const Users = () => {
  const [usersContext, setUsersContext] = useUsers();
  const [authContext] = useAuth();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          usersContext.sortBy === sortBy.id &&
          usersContext.direction === direction
        ) {
          return;
        }
        setUsersContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setUsersContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [setUsersContext, usersContext.direction, usersContext.sortBy]
  );

  const {
    data: users,
    error: usersErr,
    isLoading: isLoadingUsers,
    get: getUsers,
  } = useGetUsers();

  const [deleteUserId, setUserDeleteId] = useState();

  const {
    data: deleteUserData,
    error: deleteUserErr,
    deleteUser,
  } = useDeleteUser(deleteUserId);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createUserModal, setCreateUserModal] = useState(false);

  const [editUserModal, setEditUserModal] = useState(false);

  useEffect(() => {
    if (deleteUserId) {
      deleteUser();
    }
  }, [deleteUser, deleteUserId]);

  useEffect(() => {
    if (deleteUserData) {
      setInformationModal({
        isOpen: true,
        title: "Delete User",
        body: "User deleted successfully.",
      });
      setUsersContext({
        action: ACTIONS.REFRESH,
      });
    }
  }, [deleteUserData, setUsersContext]);

  useEffect(() => {
    if (deleteUserErr) {
      setInformationModal({
        isOpen: true,
        title: "Delete User",
        body: "There was an error with your request.",
      });
    }
  }, [deleteUserErr]);

  const onEdit = (user) => setEditUserModal(user);

  const onDelete = (user) => {
    if (user.id === authContext.currentUser.id) {
      return setInformationModal({
        isOpen: true,
        title: "Delete User",
        body: "Can't delete yourself.",
      });
    }
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setUserDeleteId(user.id);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Delete User",
      body: `Are you sure you want to delete ${user.name}?`,
      confirmColor: "danger",
    });
  };

  const setUsersContextCb = useCallback(
    (data) => setUsersContext(data),
    [setUsersContext]
  );

  useEffect(() => {
    getUsers({
      search: usersContext.search,
      page: usersContext.page - 1,
      pageSize: usersContext.sizePerPage,
      sortBy: usersContext.sortBy,
      direction: usersContext.direction,
    });
  }, [
    getUsers,
    usersContext.direction,
    usersContext.search,
    usersContext.sortBy,
    usersContext.page,
    usersContext.sizePerPage,
    usersContext.refresh,
  ]);

  useEffect(() => {
    if (users) {
      setUsersContextCb({
        action: ACTIONS.GET_USERS_SUCCESS,
        payload: { users },
      });
    }
  }, [users, setUsersContextCb]);

  useEffect(() => {
    if (usersErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [usersErr]);

  const columns = [
    {
      accessor: "firstName",
      Header: "First Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { firstName } = rowData.row.original;
        return firstName || "-";
      },
    },
    {
      accessor: "lastName",
      Header: "Last Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { lastName } = rowData.row.original;
        return lastName || "-";
      },
    },
    {
      accessor: "email",
      Header: "Email",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return email || "-";
      },
    },
    {
      accessor: "role.name",
      Header: "Role",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => {
        const { role } = rowData.row.original;
        return <Badge>{role.name || "-"}</Badge>;
      },
    },
    {
      accessor: "id",
      Header: "Actions",
      disableSortBy: true,
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => (
        <div>
          <Button
            size="sm"
            color="warning"
            className="rounded"
            onClick={() => onEdit(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faPen} className="mr-1" />
            <span>Edit</span>
          </Button>{" "}
          <Button
            size="sm"
            className="rounded"
            color="danger"
            onClick={() => onDelete(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-1" />
            <span>Delete</span>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Container fluid className="h-100">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={usersContext.users.data || []}
          pageSize={usersContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: usersContext.sortBy,
            direction: usersContext.direction,
          }}
        >
          <Card className="mb-3 w-100">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Users</h3>
                <small className="text-muted ml-2 pt-1">
                  ({usersContext.users.count})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={usersContext.search}
                    onChange={(evt) =>
                      setUsersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() => setCreateUserModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setUsersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <AdvanceTable
                table
                isLoading={isLoadingUsers}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={usersContext.users.count}
                pageCount={usersContext.users.totalPages}
                currentPage={usersContext.page - 1}
                onPageChange={(page) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={usersContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createUserModal ? (
        <UserModal
          onSubmit={() => {
            setCreateUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Create User",
              body: "User Created Succesfully",
              onClose: null,
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setCreateUserModal(false)}
        />
      ) : editUserModal ? (
        <UserModal
          onSubmit={() => {
            setEditUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Update User",
              body: "User Updated Succesfully",
              onClose: null,
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => {
            setEditUserModal(false);
          }}
          user={editUserModal}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
    </Container>
  );
};

export default Users;
