import React, { useEffect, useState } from "react";

import { useGetConversionRate } from "../../../api/Orders.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Card, CardBody, CardSubtitle } from "reactstrap";

const ConversionRate = () => {
  const {
    data: conversionRate,
    error: conversionRateErr,
    isLoading: isLoadingConversionRate,
    get: getConversionRate,
  } = useGetConversionRate();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ conversionRate: 0 });

  useEffect(() => {
    getConversionRate();
  }, [getConversionRate]);

  useEffect(() => {
    if (conversionRate) {
      setData(conversionRate);
    }
  }, [conversionRate]);

  useEffect(() => {
    if (conversionRateErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [conversionRateErr]);

  return (
    <div className="col-4">
      <div className="pl-0">
        <Card className="card-rounded">
          <CardBody className="pl-4">
          {
            isLoadingConversionRate ? (
              <Loader size="sm"/>
            ) : (
              <div>
                <h1 className="font-weight-bold display-4">{`${data.conversionRate} %`}</h1>
                <CardSubtitle className="">Conversion Rate</CardSubtitle>
              </div>
            )
          }
          </CardBody>
        </Card>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default ConversionRate;
