import moment from "moment";

export const utils = {
  buildQueryString: (data) =>
    Object.keys(data)
      .filter((d) => data[d])
      .map((d) => `${d}=${data[d]}`)
      .join("&"),
  formatCurrency: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits,
    }).format(number),
  formatDecimal: (number, maximumFractionDigits = 2) =>
    new Intl.NumberFormat("en-US", { maximumFractionDigits }).format(number),
  formatPercent: (progress, total) =>
    `${total ? ((progress / total) * 100).toFixed(2) : 0}%`,
  formatDate: (date, format = "YYYY-MM-DD") =>
    (date ? moment(date) : moment()).format(format),
  formatDateTime: (date, format = "YYYY-MM-DD, h:mm a") =>
    (date ? moment(date) : moment()).format(format),
  capitalize: (text) =>
    text.charAt(0).toUpperCase() + text.toLowerCase().slice(1),
  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },
  timeFromSeconds: (secs, separator) => {
    const pad = (num) => ("0" + num).slice(-2);
    let minutes = Math.floor(secs / 60);
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    secs = secs % 60;
    return separator
      ? `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
      : `${hours}h ${pad(minutes)}min`;
  },
  formatTime: (seconds) => {
    const minutes = Math.floor(parseInt(seconds) / 60);
    const hh = Math.floor(minutes / 60);
    const mm = minutes % 60;
    return `${hh < 10 ? `0${hh}` : hh}h ${mm < 10 ? `0${mm}` : mm}m`;
  },
  chartOptions: {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: true,
        labels: {
          font: {
            size: 18,
          },
        },
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => ` Attendance ${tooltipItem.formattedValue}%`,
        },
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          callback: (value) => `${value}%`,
          font: {
            size: 16,
          },
        },
      },
      x: {
        ticks: {
          font: {
            size: 16,
          },
          padding: 20,
        },
      },
    },
    maintainAspectRatio: false,
  },
  daysOfWeek: [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ],
  getJobCustomerName: (job) => {
    const customerName = job.customer ? job.customer : "-";
    return job.jobCustomer ? job.jobCustomer.name : customerName;
  },
  getJobCustomerCode: (job) => {
    const customerName = job.customer ? job.customer : "-";
    return job.jobCustomer ? job.jobCustomer.code : customerName;
  },
  dateFormat: "MM/DD/YYYY",
  slideInterval: process.env.REACT_APP_SLIDE_INTERVAL || 5000,
};
