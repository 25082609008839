import React from "react";
import CompletedTaskList from "./time/CompletedTaskList";
import TopPerformers from "../../components/dashboards/time/TopPerformers";
import TeamPercentTrackingHours from "../../components/dashboards/time/TeamPercentTrackingHours";

const Time = () => {
  return (
    <div className="w-100">
      <TeamPercentTrackingHours />
      <div className="row">
        <div className="col-6">
          <TopPerformers />
        </div>
        <div className="col-6">
          <CompletedTaskList />
        </div>
      </div>
    </div>
  );
};

export default Time;
