import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  isOpen: true,
  expandVisible: false,
};

const ACTIONS = {
  TOGGLE: "TOGGLE",
  CLOSE: "CLOSE",
  OPEN: "OPEN",
  SET_HAMBURGER_VISIBLE: "SET_HAMBURGER_VISIBLE",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.OPEN: {
      const newState = { ...state };
      return {
        ...newState,
        isOpen: true,
      };
    }

    case ACTIONS.CLOSE: {
      const newState = { ...state };
      return {
        ...newState,
        isOpen: false,
      };
    }

    case ACTIONS.SET_HAMBURGER_VISIBLE: {
      const { payload } = data;
      const { value } = payload;
      return {
        ...state,
        expandVisible: value,
      };
    }

    case ACTIONS.TOGGLE: {
      const newState = { ...state };
      return {
        ...newState,
        isOpen: !state.isOpen,
      };
    }

    default:
      throw new Error();
  }
};

const SidebarContext = createContext(initialState);

const SidebarProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <SidebarContext.Provider value={stateAndDispatch}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);

export { SidebarContext, SidebarProvider, ACTIONS };
