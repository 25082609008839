import React, { useEffect, useState } from "react";
import { useGetOutstandingARTotal } from "../../../api/Finance.hooks";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { Card, CardBody, CardSubtitle } from "reactstrap";

const formatter = new Intl.NumberFormat();

const OutstandingAR = () => {
  const {
    data: outstandingARTotal,
    error: outstandingARErr,
    isLoading: isLoadingOutstandingAR,
    get: getOutstandingARTotal,
  } = useGetOutstandingARTotal();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [data, setData] = useState({ total: 0 });

  useEffect(() => {
    getOutstandingARTotal();
  }, [getOutstandingARTotal]);

  useEffect(() => {
    if (outstandingARTotal) {
      setData(outstandingARTotal);
    }
  }, [outstandingARTotal]);

  useEffect(() => {
    if (outstandingARErr) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "There was an error with your request.",
      });
    }
  }, [outstandingARErr]);

  return (
    <div className="col-6">
      {isLoadingOutstandingAR ? (
        <Loader />
      ) : (
        <div>
          <Card className="card-rounded">
            <CardBody className="pl-4">
              <h1 className="font-weight-bold display-5">{`$${formatter.format(
                data.total
              )}`}</h1>
              <CardSubtitle className="">Outstanding AR Total</CardSubtitle>
            </CardBody>
          </Card>
        </div>
      )}
      {informationModal.isOpen ? (
        <InformationModal
          {...informationModal}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false })
          }
        />
      ) : null}
    </div>
  );
};

export default OutstandingAR;
