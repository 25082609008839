import React from "react";
import OutstandingAP from "../../components/dashboards/finance/OutstandingAP";
import OutstandingAR from "../../components/dashboards/finance/OutstandingAR";
import CashBalance from "../../components/dashboards/finance/CashBalance";
import ProjectedCashBalance from "../../components/dashboards/finance/ProjectedCashBalance";

const Finance = () => {
  return (
    <div className="d-flex flex-wrap w-100">
      <OutstandingAP />
      <OutstandingAR />
      <CashBalance />
      <ProjectedCashBalance />
    </div>
  );
};

export default Finance;
